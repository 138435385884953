import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import BasicTable from "components/basicTable";
import { graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "memory/expansion"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/memory16gb.png"}) { ...eyecatchImg },
    taskmanager6gb: file(relativePath: { eq: "memory/task-manager-6gb.jpg"}) { ...normalImg },
    memorymap: file(relativePath: { eq: "memory/memorymap.png"}) { ...normalImg },
    taskgame8gb: file(relativePath: { eq: "memory/task-game-8gb.PNG"}) { ...normalImg },
    ff15bench8gb: file(relativePath: { eq: "memory/ff15bench-8gb.PNG"}) { ...normalImg },
    taskmanager16gb: file(relativePath: { eq: "memory/task-manager-16gb.PNG"}) { ...normalImg },
    taskgame16gb: file(relativePath: { eq: "memory/task-game-16gb.PNG"}) { ...normalImg },
    ff15bench16gb: file(relativePath: { eq: "memory/ff15bench-16gb.PNG"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`16GBのメモリでパソコン作業はどう変わるか？`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="16GB Memory" mdxType="Image" />
    <p>{`ゲームはあまりしないかつ、簡単な動画編集や画像編集をたまに行い、普段はWebページやYoutubeの閲覧やExcel表をいじる程度の人がメモリを8GBから16GBに増設してみる。`}</p>
    <p>{`結果として高速化や安定化のメリットが論理的、体感的に得られるのかということを実証実験してみる。果たして効果はあるのだろうか？`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "8GBだと物理メモリ量を上回る仮想メモリを使用",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#8GB%E3%81%A0%E3%81%A8%E7%89%A9%E7%90%86%E3%83%A1%E3%83%A2%E3%83%AA%E9%87%8F%E3%82%92%E4%B8%8A%E5%9B%9E%E3%82%8B%E4%BB%AE%E6%83%B3%E3%83%A1%E3%83%A2%E3%83%AA%E3%82%92%E4%BD%BF%E7%94%A8",
        "aria-label": "8GBだと物理メモリ量を上回る仮想メモリを使用 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`8GBだと物理メモリ量を上回る仮想メモリを使用`}</h2>
    <p>{`まずは現状のメモリ使用状況を把握する。現在PowerPoint、PhotoShop、ブラウザ(Google Chrome)タブ10個、プログラミング用のテキストエディタ(VsCode)を開いているが、この状態でタスクマネージャーを開いたところ、次のような状態であった。`}</p>
    <Image {...props} name="taskmanager6gb" caption="タスクマネージャーでのメモリ使用状況" mdxType="Image" />
    <p>{`赤枠で囲んだ部分がポイントだが、用語の解説は後回しにして、メモリ使用の観点からそれぞれ説明すると下記のようになる。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>メモリ項目</th>
      <th>説明</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>使用中(圧縮)</td>
      <td>現在使用している物理メモリの量。圧縮はメモリの効率利用の仕組みだが、特に気にしなくても良い。</td>
    </tr>
    <tr>
      <td>利用可能</td>
      <td>利用可能な状態の物理メモリの量</td>
    </tr>
    <tr>
      <td>コミット済み</td>
      <td>使用中の仮想メモリ/確保済みの仮想メモリ</td>
    </tr>
    <tr>
      <td>キャッシュ済み</td>
      <td>ファイルを物理メモリにキャッシュしている量</td>
    </tr>
    <tr>
      <td>ページプール</td>
      <td>OSのカーネルが確保しているメモリのうち、ページアウトが可能なもの</td>
    </tr>
    <tr>
      <td>非ページブール</td>
      <td>OSのカーネルが確保しているメモリのうち、ページアウトが不可能なもの</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`上記の表で分からないことが多いと思うので解説していく。`}</p>
    <p>{`まず前提として、物理メモリ(実際のメモリモジュール)の容量が足りない場合には補助記憶装置であるストレージ、すなわちHDDやSSDをメモリの代わりとして使用する。`}</p>
    <p>{`使われ方としては、物理メモリ内にロードされているデータのうち、使用頻度が相対的に低いデータがストレージに行き、頻繁にアクセスされるデータは物理メモリ上にとどまる。`}</p>
    <p>{`そして、ストレージ内のデータにアクセスされた場合には、一旦ストレージの内容を物理メモリにロードしなおしてからデータにアクセスがなされる。`}</p>
    <p>{`このように実際には`}<strong parentName="p">{`裏でストレージを効率的に利用して、見せかけの容量を増やしたメモリを仮想メモリ`}</strong>{`と呼ぶ。`}</p>
    <p>{`また`}<strong parentName="p">{`物理メモリから補助記憶装置(=ストレージ)へデータを退避させることをページアウト`}</strong>{`、逆に`}<strong parentName="p">{`補助記憶装置から物理メモリへデータを移動させることをページイン`}</strong>{`と呼ぶ。`}</p>
    <p>{`ページとは一定の大きさを持ったデータの塊のことであり、ページ単位でやりとりをすることで、物理メモリと補助記憶装置とのやりとりを円滑に行うことができる。`}</p>
    <p>{`これでメモリ項目の説明のうち物理メモリ、仮想メモリ、ページアウトについては理解できたと思う。`}</p>
    <p>{`次に、メモリで確保するものはプログラミングを動作させるために確保されるデータ領域の他にファイルがある。ストレージにあるファイルをロードしておき、次にアクセスがあった時に素早く取り出せるように準備しているのである。`}</p>
    <p><strong parentName="p">{`キャッシュ済みとは、このメモリ上にロードされたファイルの総量`}</strong>{`である。1.9GBのファイルがメモリ上にロードされていることが分かる。`}</p>
    <p><strong parentName="p">{`メモリが必要となった時は即座にこのキャッシュ部分は開放されるため、表示上は空きに含まれる`}</strong>{`。今回の場合、空き領域も1.9GBのため、空き領域のほぼ全てがファイルキャッシュとして使われている事が分かる。`}</p>
    <p>{`ページプールと非ページプールはWindowsを動かすためのコア部分(OSの`}<strong parentName="p">{`カーネル`}</strong>{`)で使用されているメモリである。常に高速なアクセスが求められるためにストレージへのページアウトが不可能なものと、可能なものの２つに分類されている。`}</p>
    <p>{`ページプールと非ページプールは既に使用中の物理メモリの中に含まれているため、あまり意識しなくても良いだろう。`}</p>
    <h3 {...{
      "id": "メモリの現状から分かること",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A1%E3%83%A2%E3%83%AA%E3%81%AE%E7%8F%BE%E7%8A%B6%E3%81%8B%E3%82%89%E5%88%86%E3%81%8B%E3%82%8B%E3%81%93%E3%81%A8",
        "aria-label": "メモリの現状から分かること permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`メモリの現状から分かること`}</h3>
    <p>{`一通り説明したところで現在の状態をまとめると図のようになる。`}</p>
    <Image {...props} name="memorymap" caption="メモリ使用状況" mdxType="Image" />
    <p>{`ここから分かることは、`}<strong parentName="p">{`低速なストレージを5.4GBも仮想メモリで使用している`}</strong>{`ということである。`}</p>
    <p>{`使用中の5.4GBは、アルゴリズムによると使用頻度が低いものが格納されており、それほどアクセスされることは無いと思われる。`}</p>
    <p>{`しかし`}<strong parentName="p">{`ページイン・ページアウトが頻繁に発生する現象はスラッシングと呼ばれており低速の原因となる`}</strong>{`。この現象が全く発生していない事は証明できない。`}</p>
    <p>{`仮にこのスラッシングがそれなりに発生しているようならば、メモリを増設することによって体感的に差があるレベルで速くなるのではないかと想像できる。`}</p>
    <h3 {...{
      "id": "記憶装置種類別の転送速度",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E8%A8%98%E6%86%B6%E8%A3%85%E7%BD%AE%E7%A8%AE%E9%A1%9E%E5%88%A5%E3%81%AE%E8%BB%A2%E9%80%81%E9%80%9F%E5%BA%A6",
        "aria-label": "記憶装置種類別の転送速度 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`記憶装置種類別の転送速度`}</h3>
    <p>{`また、メモリと比べストレージは転送速度が遅いと言われているが、どれぐらいの差があるかはハッキリさせておきたい。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>記憶装置名</th>
      <th>転送速度</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>メモリ(DRAM)</td>
      <td>21.3GB/s(DDR4-2666の場合。1秒間に25.6ギガバイトのデータを転送可能)</td>
    </tr>
    <tr>
      <td>NVMe SSD</td>
      <td>3.5GB/s程度(現行最速のものもシーケンシャルな読み込み速度)</td>
    </tr>
    <tr>
      <td>Sata SSD</td>
      <td>500MB/s程度</td>
    </tr>
    <tr>
      <td>HDD</td>
      <td>200MB/s程度</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`管理人の現在のパソコンはDDR4-2666のメモリとSata SSDであるため`}<strong parentName="p">{`転送速度に40倍程度の開きがある`}</strong>{`ことが分かった。最速のNVMeを積んでいるとしてもその差は大きい。`}</p>
    <p>{`メモリの増設によりストレージへのアクセスを減らすことで、その時間は大きく短縮できそうである。`}</p>
    <h3 {...{
      "id": "ベンチマークも確認",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%99%E3%83%B3%E3%83%81%E3%83%9E%E3%83%BC%E3%82%AF%E3%82%82%E7%A2%BA%E8%AA%8D",
        "aria-label": "ベンチマークも確認 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ベンチマークも確認`}</h3>
    <p>{`メモリの使用量がそこそこあるものとしてはゲームがある。FF15のベンチマークを起動してみたところ次のようになった。`}</p>
    <Image {...props} name="taskgame8gb" alt="FF15起動後のメモリの状況" mdxType="Image" />
    <p>{`ここから分かることは、メモリの使用率が7.4GBと94%の使用まで上がっていることである。また、仮想メモリが21.4GBから23.4GBまで増加している。`}</p>
    <p>{`現状のメモリではそろそろ不足しそうな感じがあり、`}<strong parentName="p">{`感覚的にはわずかだが重くなったと意識すれば感じられるレベル`}</strong>{`である。`}</p>
    <p>{`ちなみにベンチマークの結果は次のようであった。`}</p>
    <Image {...props} name="ff15bench8gb" alt="8GBでのFF15ベンチ結果" mdxType="Image" />
    <p>{`タスクマネージャーを見てもGPUは100%を使い切っているものの、CPUとメモリはまだ数%であるが余力を残した状態である。この場合のボトルネックとして大きい部分はGPUにありそうである。`}</p>
    <p>{`ともあれメモリも逼迫してきており、仮想メモリの容量を引き上げるようOSが判断している事になるので、メモリを増やすことによって改善が図れそうな感じはする。`}</p>
    <p>{`ここまでの考察と16GBへした時の期待をまとめると次のようになる。`}</p>
    <ul className="cp_list">
  <li>メモリを8GB増やすことで使用されるストレージへのアクセスが減り高速化される</li>
  <li>ゲーム起動中はメモリがやや逼迫している状態なので体感的な向上が得られる</li>
    </ul>
    <p>{`さて実際どうなったのか？`}</p>
    <h2 {...{
      "id": "16GBへメモリを拡張後、論理的体感的な変化はどうか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#16GB%E3%81%B8%E3%83%A1%E3%83%A2%E3%83%AA%E3%82%92%E6%8B%A1%E5%BC%B5%E5%BE%8C%E3%80%81%E8%AB%96%E7%90%86%E7%9A%84%E4%BD%93%E6%84%9F%E7%9A%84%E3%81%AA%E5%A4%89%E5%8C%96%E3%81%AF%E3%81%A9%E3%81%86%E3%81%8B%EF%BC%9F",
        "aria-label": "16GBへメモリを拡張後、論理的体感的な変化はどうか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`16GBへメモリを拡張後、論理的/体感的な変化はどうか？`}</h2>
    <p>{`8GBのメモリをAmazonで購入して挿して見て、ブラウザでタブ15個程度、PhotoShop、プログラミング用のエディタを立ち上げてメモリの使用状況を確認したところ次のようになった。`}</p>
    <Image {...props} name="taskmanager16gb" alt="16GBへ増設後のメモリの使用状況" mdxType="Image" />
    <p>{`仮想メモリに占める物理メモリの量が増えることを予想していたが、実際には使用中が6.1GBでコミット済が9.7GBであり、`}<strong parentName="p">{`仮想メモリのうちの3.6GBと多くがストレージで使われていた`}</strong>{`。利用可能が9.8GBあるのにも関わらずである。`}</p>
    <p>{`おそらくメモリの管理機構では、影響が出ない範囲である一定量のデータをストレージに割り当てているのだろう。ディスク1(C:)を見ると、ディスクへのアクセスもほとんど走っていない事も分かるので、割り当て量自体は大きくともアクセス自体が少ないのでメモリ速度への影響が少ないことが予想できる。`}</p>
    <p>{`差があるとするとキャッシュ済が増えたことであろう。`}<strong parentName="p">{`多くのファイルをキャッシュしておくことで、不意のアクセス切り替えがあったとしても素早く反応することができる`}</strong>{`。`}</p>
    <p>{`ただ、様々なアプリを立ち上げたり閉じたりして暫くパソコンを弄っていていたが`}<strong parentName="p">{`体感的には特に速くなったとは感じなかった`}</strong>{`。`}</p>
    <h3 {...{
      "id": "16GBでのベンチマークを確認",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#16GB%E3%81%A7%E3%81%AE%E3%83%99%E3%83%B3%E3%83%81%E3%83%9E%E3%83%BC%E3%82%AF%E3%82%92%E7%A2%BA%E8%AA%8D",
        "aria-label": "16GBでのベンチマークを確認 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`16GBでのベンチマークを確認`}</h3>
    <p>{`次に8GBの時と同様にゲームを起動してみた。この時のメモリ使用状況は下記のようになった。`}</p>
    <Image {...props} name="taskgame16gb" alt="16GBへ増設後のメモリの使用状況" mdxType="Image" />
    <p>{`使用中のメモリが8GBをここで初めて超え、16GBにした効果が出たと言える。`}<strong parentName="p">{`体感的にもベンチマークの立ち上げ時に8GBの時は一瞬カクツキあったが、今回は滑らか`}</strong>{`であった。`}</p>
    <p>{`ベンチマークの点数は下記のようであった。`}</p>
    <Image {...props} name="ff15bench16gb" alt="16GBでFF15起動後のメモリの状況" mdxType="Image" />
    <p>{`全体としては2%スコアが上昇した。これがデュアルチャネル化によるものなのか、メモリの安定性が増したからなのかは分からないが、`}<strong parentName="p">{`8GBを超えるメモリを活用できているにも関わらず大してスコアは伸びなかった`}</strong>{`。`}</p>
    <p>{`最も、明らかにメモリの使用率が100%に達しているような状態が続いているようであったならば大きく改善するであろうが。`}</p>
    <h2 {...{
      "id": "メモリが十分な場合8GBから16GBへ増設しても効果は薄い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%A1%E3%83%A2%E3%83%AA%E3%81%8C%E5%8D%81%E5%88%86%E3%81%AA%E5%A0%B4%E5%90%888GB%E3%81%8B%E3%82%8916GB%E3%81%B8%E5%A2%97%E8%A8%AD%E3%81%97%E3%81%A6%E3%82%82%E5%8A%B9%E6%9E%9C%E3%81%AF%E8%96%84%E3%81%84",
        "aria-label": "メモリが十分な場合8GBから16GBへ増設しても効果は薄い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`メモリが十分な場合8GBから16GBへ増設しても効果は薄い`}</h2>
    <p>{`以上の実験から分かったことは以下の通りである。`}</p>
    <ul className="cp_list">
  <li>仮想メモリが有効に使われており、80%程度の使用率までなら速度が体感的に落ちることはなく、それ以上であっても大きくは落ちない</li>
  <li>ゲームの起動など、急にメモリを多く使用したときにページングや、追加の仮想メモリを確保する影響か若干カクツキが見られたが、16GBの増設により見られなくなった</li>
    </ul>
    <p><strong parentName="p">{`体感的には大きなメモリを使用するアプリの起動時に稀に見られるカクツキを抑え、安定感を向上させる`}</strong>{`という効果が得られた。(と思うがスコアも伸びていないのでプラシーボかもしれない。。)`}</p>
    <p>{`これが8GBのコストに見合うのかと聞かれると、安心感を得られるため見合うと答える人もいるかもしれないが、多くの人はNoであると思われる。`}</p>
    <p>{`また、圧縮ファイルの解凍など、大量のメモリを搭載した方が有利に働くケースが多いが、それも頻繁にする操作でなければメリットは薄い。`}</p>
    <p>{`よって`}<strong parentName="p">{`メモリは100%に近い使用状態へ定常的になり、そうなった場合にはいつもブラウザのタブの数を減らすなど苦労しているような状況でない限り、コストに見合ったメリットは受けられない`}</strong>{`と感じた。`}</p>
    <p>{`仮想メモリの使用量が多いからという理由ではなく、`}<strong parentName="p">{`複数アプリを起動している時に、体感的に明らかに遅くなったなと感じた時が増設の機会`}</strong>{`となるだろう。`}</p>
    <p>{`特に、メモリの増設はメモリースロットにメモリを挿すだけで認識してくれるため、購入時に足りるかどうかで悩んだ場合は、最初控えめにしておき、足らなかったら増設するという方法がおすすめである。`}</p>
    <p>{`使用するアプリによっては16GBにした方が明らかに良いケースも多いので、この記事も参考にしつつタスクマネージャーでのメモリの使用状況と、実際の体感とを比較しつつ購入を決めていこう。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      